.home {
  background-repeat: no-repeat;
  height: 92vh;
  background-position: center center;
  background-size: cover;
  background-color: #284e47;
}

.home > html {
  overflow-y: hidden;
}
