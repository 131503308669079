.MuiTab-root{
  font-family: 'Quicksand' !important;
  font-size: 1.5rem !important;
}

.PrivateTabIndicator-colorSecondary-9 {
  background-color: #284e47 !important;
}

.PrivateTabIndicator-colorSecondary-13 {
  background-color: #022406 !important;
}

.PrivateTabIndicator-colorSecondary-1 {
  background-color: #022406 !important;
}

.PrivateTabIndicator-colorSecondary-3 {
  background-color: #022406 !important;
}

.jss9 {
  background-color: #022406 !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: #022406 !important;
}

.MuiTab-textColorPrimary {
  color: #022406 !important;
}

.image__container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 90vw;
}

.image__cont {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image__container > img {
  object-fit: contain;
  height: 240px;
  margin: 20px;
}

.image__container > img:hover {
  cursor: pointer;
}
