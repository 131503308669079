.comoLlegar__title {
  margin-top: 20px;
  font-size: 3rem;
  text-align: center;
}

.comoLlegar__info {
  display: flex;
  align-items: center;
  justify-content: center;
}

.comoLlegar__data {
  border: 2px solid #284e47;
  padding: 30px;
  width: 40vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: absolute;
  right: 50vw;
  top: 40vh;
  z-index: 1;
}

a {
  color: #284e47;
  text-decoration: none;
}

.comoLlegar__image > a > img {
  object-fit: contain;
  width: 700px;
  position: absolute;
  left: 40vw;
  top: 25vh;
  z-index: 0;
}

@media only screen and (max-width: 414px) {
  .comoLlegar__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  .comoLlegar__image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  .comoLlegar__image > a > img {
    width: 300px;
    position: static;
  }
  .comoLlegar__data {
    border: 2px solid #284e47;
    padding: 30px;
    width: 300px;
    display: flex;
    align-items: center;
    position: static;
  }
}

@media only screen and (max-width: 365px) {
  .comoLlegar__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  .comoLlegar__image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  .comoLlegar__image > a > img {
    width: 200px;
    position: static;
  }
  .comoLlegar__data {
    border: 2px solid #284e47;
    padding: 30px;
    width: 250px;
    display: flex;
    align-items: center;
    position: static;
  }
}
