.nosotros {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.nosotros__title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  text-align: center;
  font-size: 3rem;
}

.nosotros__description {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  text-align: center;
  font-size: 1.5rem;
}

.nosotros__description2{
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  text-align: center;
  font-size: 2rem;
}

@media only screen and (max-width: 600px) {
  .nosotros__description {
    font-size: 2rem;
    width: 90%;
  }
}