.contacto__title {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-size: 3rem;
}

.contacto__info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.contacto__image > img {
  object-fit: contain;
  width: 500px;
  position: absolute;
  right: 40vw;
  z-index: 0;
}

.contacto__data {
  border: 2px solid #284e47;
  padding: 30px;
  width: 30vw;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  position: absolute;
  left: 50vw;
  top: 40vh;
  z-index: 1;
}

.contacto__data > p {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
}

.contacto__data > p > a {
  text-decoration: none;
  color: #284e47;
}

@media only screen and (max-width: 414px) {
  .contacto__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  .contacto__image {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .contacto__image > img {
    width: 300px;
    position: static;
  }
  .contacto__data {
    border: 2px solid #284e47;
    padding: 30px;
    width: 60vw;
    margin-top: 20px;
    display: flex;
    align-items: center;
    position: static;
  }
}
