.servicio {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px;
  width: 120px;
  object-fit: contain;
  height: 120px;
}

.servicio__name {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
