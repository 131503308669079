.nosotrosEachLeft {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px;
}

.nosotrosEachLeft > img {
  object-fit: contain;
  height: 240px;
  margin: 0px 20px;
}

@media only screen and (max-width: 600px) {
  .nosotrosEachLeft > img {
    display: none;
  }
  .nosotros__EachLeftdescription{
    font-size: 1.5rem;
  }
}
