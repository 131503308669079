body {
  margin: 0;
  font-family: 'Quicksand' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e4d9bd;
}


* {
  margin: 0;
}

@font-face {
  font-family: "Bilanesa";
  src: local("Bilanesa"),
    url('./fonts/bilanesa/Bilanesa.ttf') format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Quicksand";
  src: local("Quicksand"),
    url('./fonts/quicksand/Quicksand_Light.otf') format("truetype");
  font-weight: normal;
}

