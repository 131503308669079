.servicios{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.servicios__title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  text-align: center;
  font-size: 3rem;
}

.servicios__all {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 80%;
}
